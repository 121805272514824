<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <b-img
          :src="appLogoImage"
          width="40"
          height="40"
          alt="logo"
        />
        <h2 class="brand-text">
          {{ appName }}
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h3"
            class="font-weight-bold mb-1"
          >
            Bienvenue sur {{ appName }}👋
          </b-card-title>
          <b-card-text class="mb-2">
            Veuillez cliquer sur ce bouton pour vous authentifier.
          </b-card-text>

          <b-button
            type="submit"
            variant="primary"
            pill
            block
            :href="TRUSTEDX_AUTHORIZATION_URI"
          >
            S'AUTHENTIFIER
            <feather-icon
              size="14"
              icon="ArrowRightIcon"
            />
          </b-button>

        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { $themeConfig } from '@themeConfig'
// eslint-disable-next-line import/no-cycle
import store from '@/store/index'

import {
  BRow, BCol, BLink, BCardText, BCardTitle, BImg, BButton,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import useAuth from '@/services/auth/authService'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BCardText,
    BCardTitle,
    BImg,
    BButton,
  },
  setup() {
    const { TRUSTEDX_AUTHORIZATION_URI } = useAuth()
    const { appName, appLogoImage } = $themeConfig.app
    const sideImg = require('@/assets/images/pages/login-v2.svg')

    return {
      appName,
      appLogoImage,
      sideImg,
      TRUSTEDX_AUTHORIZATION_URI,
    }
  },
  computed: {

    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
